<template>
  <div class="multiHeaderContainer">
    <!-- <b-card> -->
    <div v-if="this.isEmptyFlag">
      <div class="spinnerCentered">
        <h6 v-if="this.roleType === 'ADMIN'" class="p-4">Data not found. Please create a project to proceed.</h6>
        <h6 class="p-4" v-else>Data not found. Please contact your administrator to create a project before proceeding.</h6>
      </div>
    </div>
    <div v-else>
      <div style="display: flex; justify-content: space-between">
        <div>
          <b-card-title>
            <h3 class="app-title mdsm">{{ this.projectName }}</h3>
          </b-card-title>
        </div>
        <!-- {{ this.tableData.leadSquared.length }}--- -->
        <div style="display: flex; justify-content: space-between; gap: 5px;">
          <div style="margin-top: -15px; margin-bottom: 10px;">
            <label>Channel:</label>
            <b-form-select
              v-model="selectedAdsOption"
              style="text-transform: capitalize;"
            >
              <template v-for="(option, index) in allOptions">
                <option
                  :key="index"
                  :value="
                    option === 'Google Ads'
                      ? 'googleAds'
                      : option === 'Lead Squared'
                      ? 'leadSquared'
                      : option
                  "
                >
                  {{
                    option === "Google Ads"
                      ? "Google Ads"
                      : option === "Lead Squared"
                      ? "leadSquared"
                      : option
                  }}
                </option>
              </template>
            </b-form-select>
          </div>
        </div>
      </div>
      <div v-if="this.type === 'leadSquareAds'">
        <div
          v-if="this.tableData && this.tableData.leadSquared.length === 0"
          class="spinnerCentered"
        >
          <h4>Data Not Found!</h4>
        </div>
        <div v-else>
          <div>
            <div class="table-multiheader-container">
              <div class="date-column">
                <table class="table">
                  <thead>
                    <tr>
                      <th></th>
                    </tr>
                    <tr>
                      <th>Projects</th>
                      <span></span>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in this.commonProjectNames"
                      :key="index"
                    >
                      <td class="dateProjectTd padding0">{{ item }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-for="(item, index) in filteredKeys" :key="index">
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        :colspan="
                          leadSquaredIntegrations &&
                          selectedAdsOption === 'overall'
                            ? '13'
                            : '11'
                        "
                        class="mainTh"
                        :style="{
                          backgroundColor: getProjectsBackgroundColor(index),
                        }"
                      >
                        <span
                          style="display: flex; justify-content: space-between; align-items: center;"
                        >
                          <span></span>
                          <span style="margin-top: 10px; float: right;"
                            >{{
                              item.key === "googleAds"
                                ? "Google Ads"
                                : item.key === "overall"
                                ? "Overall Insights"
                                : "" || item.key
                            }}
                          </span>
                          <span style="float: right;"
                            ><feather-icon
                              icon="DownloadIcon"
                              color="#200E6B"
                              size="18"
                              style=" cursor: pointer;"
                              @click="
                                exportData(
                                  item.dateWiseData,
                                  item.key,
                                  item.overAllInsights
                                )
                              "
                          /></span>
                        </span>
                      </th>
                    </tr>
                    <tr v-if="businessTypeProject === 'leadgen'">
                      <template v-if="selectedAdsOption === 'leadSquared'">
                        <th
                          v-for="(value, key) in projectsMap.projects['leadgen']
                            .leadSquared"
                          :key="key"
                        >
                          {{ value }}
                        </th>
                      </template>
                      <template
                        v-else-if="
                          selectedAdsOption === 'overall' &&
                            leadSquaredIntegrations
                        "
                      >
                        <template v-if="leadSquaredIntegrations">
                          <th
                            v-for="(value, key) in projectsMap.projects[
                              'leadgen'
                            ].withLeadSquared"
                            :key="key"
                          >
                            {{ value }}
                          </th>
                        </template>
                        <template v-else>
                          <th
                            v-for="(value, key) in projectsMap.projects[
                              'leadgen'
                            ].withoutLeadSquared"
                            :key="key"
                          >
                            {{ value }}
                          </th>
                        </template>
                      </template>
                      <template v-else>
                        <template>
                          <th
                            v-for="(value, key) in projectsMap.projects[
                              'leadgen'
                            ].googleAds"
                            :key="key"
                          >
                            {{ value }}
                          </th>
                        </template>
                      </template>
                    </tr>
                    <tr v-if="businessTypeProject === 'leadform'">
                      <template v-if="selectedAdsOption === 'leadSquared'">
                        <th
                          v-for="(value, key) in projectsMap.projects['leadform']
                            .leadSquared"
                          :key="key"
                        >
                          {{ value }}
                        </th>
                      </template>
                      <template
                        v-else-if="
                          selectedAdsOption === 'overall' &&
                            leadSquaredIntegrations
                        "
                      >
                        <template v-if="leadSquaredIntegrations">
                          <th
                            v-for="(value, key) in projectsMap.projects[
                              'leadform'
                            ].withLeadSquared"
                            :key="key"
                          >
                            {{ value }}
                          </th>
                        </template>
                        <template v-else>
                          <th
                            v-for="(value, key) in projectsMap.projects[
                              'leadform'
                            ].withoutLeadSquared"
                            :key="key"
                          >
                            {{ value }}
                          </th>
                        </template>
                      </template>
                      <template v-else>
                        <template>
                          <th
                            v-for="(value, key) in projectsMap.projects[
                              'leadgen'
                            ].googleAds"
                            :key="key"
                          >
                            {{ value }}
                          </th>
                        </template>
                      </template>
                    </tr>
                    <tr v-else>
                      <template v-if="leadSquaredIntegrations">
                        <th
                          v-for="(value, key) in projectsMap.projects['default']
                            .withLeadSquared"
                          :key="key"
                        >
                          {{ value }}
                        </th>
                      </template>
                      <template v-else>
                        <th
                          v-for="(value, key) in projectsMap.projects['default']
                            .withoutLeadSquared"
                          :key="key"
                        >
                          {{ value }}
                        </th>
                      </template>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="item.key === 'overall'">
                      <tr
                        v-for="(i, dataIndex) in item.overAllInsights"
                        :key="dataIndex"
                      >
                        <template v-if="leadSquaredIntegrations">
                          <template v-if="businessTypeProject == 'leadgen'">
                            <td
                              v-for="(value, key) in projectsMap.projects[
                                businessTypeProject
                              ].withLeadSquared"
                              :key="key"
                            >
                              {{
                                i &&
                                  ((i[0] &&
                                    i[0].hasOwnProperty(key) &&
                                    removeINR(i[0][key])) ||
                                    (i.hasOwnProperty(key) &&
                                      removeINR(i[key])) ||
                                    "0")
                              }}
                            </td>
                          </template>
                          <template v-else-if="businessTypeProject == 'leadform'">
                            <td
                              v-for="(value, key) in projectsMap.projects[
                                businessTypeProject
                              ].withLeadSquared"
                              :key="key"
                            >
                              {{
                                i &&
                                  ((i[0] &&
                                    i[0].hasOwnProperty(key) &&
                                    removeINR(i[0][key])) ||
                                    (i.hasOwnProperty(key) &&
                                      removeINR(i[key])) ||
                                    "0")
                              }}
                            </td>
                          </template>
                          <template v-else>
                            <td
                              v-for="(value, key) in projectsMap.projects[
                                'default'
                              ].withLeadSquared"
                              :key="key"
                            >
                              {{
                                i &&
                                  ((i[0] &&
                                    i[0].hasOwnProperty(key) &&
                                    removeINR(i[0][key])) ||
                                    (i.hasOwnProperty(key) &&
                                      removeINR(i[key])) ||
                                    "0")
                              }}
                            </td>
                          </template>
                        </template>
                        <template v-else>
                          <template v-if="businessTypeProject == 'leadgen'">
                            <td
                              v-for="(value, key) in projectsMap.projects[
                                businessTypeProject
                              ].googleAds"
                              :key="key"
                            >
                              {{
                                i &&
                                  ((i[0] &&
                                    i[0].hasOwnProperty(key) &&
                                    removeINR(i[0][key])) ||
                                    (i.hasOwnProperty(key) &&
                                      removeINR(i[key])) ||
                                    "0")
                              }}
                            </td>
                          </template>
                          <template v-else-if="businessTypeProject == 'leadform'">
                            <td
                              v-for="(value, key) in projectsMap.projects[
                                businessTypeProject
                              ].googleAds"
                              :key="key"
                            >
                              {{
                                i &&
                                  ((i[0] &&
                                    i[0].hasOwnProperty(key) &&
                                    removeINR(i[0][key])) ||
                                    (i.hasOwnProperty(key) &&
                                      removeINR(i[key])) ||
                                    "0")
                              }}
                            </td>
                          </template>
                          <template v-else>
                            <td
                              v-for="(value, key) in projectsMap.projects[
                                'default'
                              ].googleAds"
                              :key="key"
                            >
                              {{
                                i &&
                                  ((i[0] &&
                                    i[0].hasOwnProperty(key) &&
                                    removeINR(i[0][key])) ||
                                    (i.hasOwnProperty(key) &&
                                      removeINR(i[key])) ||
                                    "0")
                              }}
                            </td>
                          </template>
                        </template>
                      </tr>
                    </template>
                    <template v-if="item.key === 'googleAds'">
                      <tr
                        v-for="(i, dataIndex) in item.dateWiseData"
                        :key="dataIndex"
                      >
                        <template v-if="businessTypeProject == 'leadgen'">
                          <td
                            v-for="(value, key) in projectsMap.projects[
                              'leadgen'
                            ].googleAds"
                            :key="key"
                          >
                            {{
                              i[0][key] == undefined ? i[0].spend : i[0][key]
                            }}
                          </td>
                        </template>
                        <template v-else-if="businessTypeProject == 'leadform'">
                          <td
                            v-for="(value, key) in projectsMap.projects[
                              'leadform'
                            ].googleAds"
                            :key="key"
                          >
                            {{
                              i[0][key] == undefined ? i[0].spend : i[0][key]
                            }}
                          </td>
                        </template>
                        <template v-else>
                          <td
                            v-for="(value, key) in projectsMap.projects[
                              'default'
                            ].googleAds"
                            :key="key"
                          >
                            {{
                              i[0][key] == undefined ? i[0].spend : i[0][key]
                            }}
                          </td>
                        </template>
                      </tr>
                    </template>
                    <template v-if="item.key === 'meta'">
                      <tr
                        v-for="(i, dataIndex) in item.dateWiseData"
                        :key="dataIndex"
                      >
                        <template v-if="businessTypeProject == 'leadgen'">
                          <td
                            v-for="(value, key) in projectsMap.projects[
                              'leadgen'
                            ].googleAds"
                            :key="key"
                          >
                            {{
                              i[0][key] == undefined ? i[0].spend : i[0][key]
                            }}
                          </td>
                        </template>
                        <template v-else-if="businessTypeProject == 'leadform'">
                          <td
                            v-for="(value, key) in projectsMap.projects[
                              'leadform'
                            ].googleAds"
                            :key="key"
                          >
                            {{
                              i[0][key] == undefined ? i[0].spend : i[0][key]
                            }}
                          </td>
                        </template>
                        <template v-else>
                          <td
                            v-for="(value, key) in projectsMap.projects[
                              'default'
                            ].googleAds"
                            :key="key"
                          >
                            {{
                              i[0][key] == undefined ? i[0].spend : i[0][key]
                            }}
                          </td>
                        </template>
                      </tr>
                    </template>
                    <template v-if="item.key === 'leadSquared'">
                      <tr
                        v-for="(i, dataIndex) in item.dateWiseData"
                        :key="dataIndex"
                      >
                        <template v-if="businessTypeProject == 'leadgen'">
                          <td
                            v-for="(value, key) in projectsMap.projects[
                              'leadgen'
                            ].leadSquared"
                            :key="key"
                          >
                            {{
                              i[0][key] == undefined ? i[0].spend : i[0][key]
                            }}
                          </td>
                        </template>
                        <template v-else-if="businessTypeProject == 'leadform'">
                          <td
                            v-for="(value, key) in projectsMap.projects[
                              'leadform'
                            ].leadSquared"
                            :key="key"
                          >
                            {{
                              i[0][key] == undefined ? i[0].spend : i[0][key]
                            }}
                          </td>
                        </template>
                        <template v-else>
                          <td
                            v-for="(value, key) in projectsMap.projects[
                              'default'
                            ].leadSquared"
                            :key="key"
                          >
                            {{
                              i[0][key] == undefined ? i[0].spend : i[0][key]
                            }}
                          </td>
                        </template>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div>
          
          <div class="table-multiheader-container">
            <div class="date-column">
              <table class="table">
                <thead>
                  <tr>
                    <th></th>
                  </tr>
                  <tr>
                    <th>Projects</th>
                    <span></span>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in this.commonProjectNames"
                    :key="index"
                  >
                    <td class="dateProjectTd padding0">{{ item }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-for="(item, index) in filteredKeys" :key="index">
              <table class="table">
                <thead>
                  <tr>
                    <th
                    :colspan="
                    (leadSquaredIntegrations && selectedAdsOption === 'overall')
                      ? '20'
                      : (salesforceDataFound && selectedAdsOption === 'overall' || selectedAdsOption === 'googleAds' || selectedAdsOption === 'meta')
                        ? '14'
                        : '12'
                  "
                  
                      class="mainTh"
                      :style="{
                        backgroundColor: getProjectsBackgroundColor(index),
                      }"
                    >
                      <span
                        style="display: flex; justify-content: space-between; align-items: center;"
                      >
                        <span></span>
                        <span style="margin-top: 10px; float: right;"
                          >{{
                            item.key === "googleAds"
                              ? "Google Ads"
                              : item.key === "overall"
                              ? "Overall Insights "
                              : "" || item.key
                          }}
                        </span>
                        <span style="float: right;"
                          ><feather-icon
                            icon="DownloadIcon"
                            color="#200E6B"
                            size="18"
                            style=" cursor: pointer;"
                            @click="
                              exportData(
                                item.dateWiseData,
                                item.key,
                                item.overAllInsights
                              )
                            "
                        /></span>
                      </span>
                    </th>
                  </tr>
                  <tr v-if="businessTypeProject === 'leadgen'">
                    <template v-if="selectedAdsOption === 'leadSquared'">
                      <th
                        v-for="(value, key) in projectsMap.projects['leadgen']
                          .leadSquared"
                        :key="key"
                      >
                        {{ value }}
                      </th>
                    </template>
                    <template
                      v-else-if="
                        selectedAdsOption === 'overall' &&
                          leadSquaredIntegrations
                      "
                    >
                      <template v-if="leadSquaredIntegrations">
                        <th
                          v-for="(value, key) in projectsMap.projects['leadgen']
                            .withLeadSquared"
                          :key="key"
                        >
                          {{ value }}
                        </th>
                      </template>
                      <template v-else>
                        <th
                          v-for="(value, key) in projectsMap.projects['leadgen']
                            .withoutLeadSquared"
                          :key="key"
                        >
                          {{ value }}
                        </th>
                      </template>
                    </template>
                    <template v-else-if="salesforceDataFound">
                      <template>
                        <th
                          v-for="(value, key) in projectsMap.projects['leadgen']
                            .withLeadSalesforce"
                          :key="key"
                        >
                          {{ value }}
                        </th>
                      </template>
                    </template>
                    <template v-else>
                      <template>
                        <th
                          v-for="(value, key) in projectsMap.projects['leadgen']
                            .googleAds"
                          :key="key"
                        >
                          {{ value }}
                        </th>
                      </template>
                    </template>
                  </tr>
                  <tr v-else-if="businessTypeProject === 'leadform'">
                    <template v-if="selectedAdsOption === 'leadSquared'">
                      <th
                        v-for="(value, key) in projectsMap.projects['leadform']
                          .leadSquared"
                        :key="key"
                      >
                        {{ value }}
                      </th>
                    </template>
                    <template
                      v-else-if="
                        selectedAdsOption === 'overall' &&
                          leadSquaredIntegrations
                      "
                    >
                      <template v-if="leadSquaredIntegrations">
                        <th
                          v-for="(value, key) in projectsMap.projects['leadform']
                            .withLeadSquared"
                          :key="key"
                        >
                          {{ value }}
                        </th>
                      </template>
                      <template v-else>
                        <th
                          v-for="(value, key) in projectsMap.projects['leadform']
                            .withoutLeadSquared"
                          :key="key"
                        >
                          {{ value }}
                        </th>
                      </template>
                    </template>
                    <template v-else-if="salesforceDataFound">
                      <template>
                        <th
                          v-for="(value, key) in projectsMap.projects['leadform']
                            .withLeadSalesforce"
                          :key="key"
                        >
                          {{ value }}
                        </th>
                      </template>
                    </template>
                    <template v-else>
                      <template>
                        <th
                          v-for="(value, key) in projectsMap.projects['leadform']
                            .googleAds"
                          :key="key"
                        >
                          {{ value }}
                        </th>
                      </template>
                    </template>
                  </tr>
                  <tr v-else>
                    <template v-if="leadSquaredIntegrations">
                      <th
                        v-for="(value, key) in projectsMap.projects['default']
                          .withLeadSquared"
                        :key="key"
                      >
                        {{ value }}
                      </th>
                    </template>
                    <template v-else>
                      <th
                        v-for="(value, key) in projectsMap.projects['default']
                          .withoutLeadSquared"
                        :key="key"
                      >
                        {{ value }}
                      </th>
                    </template>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="item.key === 'overall'">
                    <tr
                      v-for="(i, dataIndex) in item.overAllInsights"
                      :key="dataIndex"
                    >
                      <template v-if="leadSquaredIntegrations">
                        <template v-if="businessTypeProject == 'leadgen'">
                          <td
                            v-for="(value, key) in projectsMap.projects[
                              businessTypeProject
                            ].withLeadSquared"
                            :key="key"
                          >
                            {{
                              i &&
                                ((i[0] &&
                                  i[0].hasOwnProperty(key) &&
                                  removeINR(i[0][key])) ||
                                  (i.hasOwnProperty(key) &&
                                    removeINR(i[key])) ||
                                  "0")
                            }}
                          </td>
                        </template>
                        <template v-else-if="businessTypeProject == 'leadform'">
                          <td
                            v-for="(value, key) in projectsMap.projects[
                              businessTypeProject
                            ].withLeadSquared"
                            :key="key"
                          >
                            {{
                              i &&
                                ((i[0] &&
                                  i[0].hasOwnProperty(key) &&
                                  removeINR(i[0][key])) ||
                                  (i.hasOwnProperty(key) &&
                                    removeINR(i[key])) ||
                                  "0")
                            }}
                          </td>
                        </template>
                        <template v-else>
                          <td
                            v-for="(value, key) in projectsMap.projects[
                              'default'
                            ].withLeadSquared"
                            :key="key"
                          >
                            {{
                              i &&
                                ((i[0] &&
                                  i[0].hasOwnProperty(key) &&
                                  removeINR(i[0][key])) ||
                                  (i.hasOwnProperty(key) &&
                                    removeINR(i[key])) ||
                                  "0")
                            }}
                          </td>
                        </template>
                      </template>
                      <template v-else-if="salesforceDataFound">
                        <template v-if="businessTypeProject == 'leadgen'">
                          <td
                            v-for="(value, key) in projectsMap.projects[
                              businessTypeProject
                            ].withLeadSalesforce"
                            :key="key"
                          >
                           <span v-if="key === 'sales' || key === 'qualityLeads' || key === 'costPerQualityLeads' || key === 'siteVisits'">
                            {{ 
                              i && 
                              (i[0] && i[0].salesforce && i[0].salesforce.hasOwnProperty(key) 
                                ? removeINR(i[0].salesforce[key]) 
                                : (i.salesforce && i.salesforce.hasOwnProperty(key) 
                                    ? removeINR(i.salesforce[key]) 
                                    : "0")
                              )
                            }}
                          </span>
                          <span v-else>
                            {{ 
                              i && 
                              (i[0] && i[0].hasOwnProperty(key) 
                                ? removeINR(i[0][key]) 
                                : (i.hasOwnProperty(key) 
                                    ? removeINR(i[key]) 
                                    : "0")
                              )
                            }}
                          </span>
                          
                           
                          </td>
                        </template>
                        <template v-if="businessTypeProject == 'leadform'">
                          <td
                            v-for="(value, key) in projectsMap.projects[
                              businessTypeProject
                            ].withLeadSalesforce"
                            :key="key"
                          >
                           <span v-if="key === 'sales' || key === 'qualityLeads' || key === 'costPerQualityLeads' || key === 'siteVisits'">
                            {{ 
                              i && 
                              (i[0] && i[0].salesforce && i[0].salesforce.hasOwnProperty(key) 
                                ? removeINR(i[0].salesforce[key]) 
                                : (i.salesforce && i.salesforce.hasOwnProperty(key) 
                                    ? removeINR(i.salesforce[key]) 
                                    : "0")
                              )
                            }}
                          </span>
                          <span v-else>
                            {{ 
                              i && 
                              (i[0] && i[0].hasOwnProperty(key) 
                                ? removeINR(i[0][key]) 
                                : (i.hasOwnProperty(key) 
                                    ? removeINR(i[key]) 
                                    : "0")
                              )
                            }}
                          </span>
                          
                           
                          </td>
                        </template>
                      
                      </template>
                      <template v-else-if="!salesforceDataFound && !leadSquaredIntegrations && businessTypeProject == 'leadgen'">
                          <td
                            v-for="(value, key) in projectsMap.projects[
                              'leadgen'
                            ].googleAds"
                            :key="key"
                          >
                          <span >
                            {{ 
                              i && 
                              (i[0] && i[0].hasOwnProperty(key) 
                                ? removeINR(i[0][key]) 
                                : (i.hasOwnProperty(key) 
                                    ? removeINR(i[key]) 
                                    : "0")
                              )
                            }}
                          </span>  
                          </td>
                      </template>
                      <template v-else-if="!salesforceDataFound && !leadSquaredIntegrations && businessTypeProject == 'leadform'">
                        <td
                          v-for="(value, key) in projectsMap.projects[
                            'leadform'
                          ].googleAds"
                          :key="key"
                        >
                        <span >
                          {{ 
                            i && 
                            (i[0] && i[0].hasOwnProperty(key) 
                              ? removeINR(i[0][key]) 
                              : (i.hasOwnProperty(key) 
                                  ? removeINR(i[key]) 
                                  : "0")
                            )
                          }}
                        </span>  
                        </td>
                    </template>
                      <template v-else>
                        <!-- -{{ projectsMap.projects[
                          'default'
                        ].googleAds }}---- -->
                        <td
                          v-for="(value, key) in projectsMap.projects[
                            'default'
                          ].googleAds"
                          :key="key"
                        >
                          {{
                            i &&
                              ((i[0] &&
                                i[0].hasOwnProperty(key) &&
                                removeINR(i[0][key])) ||
                                (i.hasOwnProperty(key) &&
                                  removeINR(i[key])) ||
                                "0")
                          }}
                        </td>
                      </template>
                    </tr>
                  </template>
                  <template v-if="item.key === 'googleAds'">
                    <tr
                      v-for="(i, dataIndex) in item.dateWiseData"
                      :key="dataIndex"
                    >
                      <template v-if="businessTypeProject == 'leadgen' && salesforceDataFound">
                        <td
                          v-for="(value, key) in projectsMap.projects['leadgen']
                            .withLeadSalesforce"
                          :key="key"
                        >
                        <span v-if="key === 'sales' || key === 'qualityLeads' || key === 'costPerQualityLeads' || key === 'siteVisits'">
                          {{ 
                            i && 
                            (i[0] && i[0].salesforce && i[0].salesforce.hasOwnProperty(key) 
                              ? removeINR(i[0].salesforce[key]) 
                              : (i.salesforce && i.salesforce.hasOwnProperty(key) 
                                  ? removeINR(i.salesforce[key]) 
                                  : "0")
                            )
                          }}
                        </span>
                        <span v-else>
                          {{ 
                            i && 
                            (i[0] && i[0].hasOwnProperty(key) 
                              ? removeINR(i[0][key]) 
                              : (i.hasOwnProperty(key) 
                                  ? removeINR(i[key]) 
                                  : "0")
                            )
                          }}
                        </span>
                        </td>
                      </template>
                      <template v-else-if="businessTypeProject == 'leadgen' && !salesforceDataFound">
                        <td
                          v-for="(value, key) in projectsMap.projects['leadgen']
                            .googleAds"
                          :key="key"
                        >
                          {{ i[0][key] == undefined ? i[0].spend : i[0][key] }}
                        </td>
                      </template>
                      <template v-else-if="businessTypeProject == 'leadform' && salesforceDataFound">
                        <td
                          v-for="(value, key) in projectsMap.projects['leadform']
                            .withLeadSalesforce"
                          :key="key"
                        >
                        <span v-if="key === 'sales' || key === 'qualityLeads' || key === 'costPerQualityLeads' || key === 'siteVisits'">
                          {{ 
                            i && 
                            (i[0] && i[0].salesforce && i[0].salesforce.hasOwnProperty(key) 
                              ? removeINR(i[0].salesforce[key]) 
                              : (i.salesforce && i.salesforce.hasOwnProperty(key) 
                                  ? removeINR(i.salesforce[key]) 
                                  : "0")
                            )
                          }}
                        </span>
                        <span v-else>
                          {{ 
                            i && 
                            (i[0] && i[0].hasOwnProperty(key) 
                              ? removeINR(i[0][key]) 
                              : (i.hasOwnProperty(key) 
                                  ? removeINR(i[key]) 
                                  : "0")
                            )
                          }}
                        </span>
                        </td>
                      </template>
                      <template v-else-if="businessTypeProject == 'leadform' && !salesforceDataFound">
                        <td
                          v-for="(value, key) in projectsMap.projects['leadform']
                            .googleAds"
                          :key="key"
                        >
                          {{ i[0][key] == undefined ? i[0].spend : i[0][key] }}
                        </td>
                      </template>
                      <template v-else>
                        <td
                          v-for="(value, key) in projectsMap.projects['default']
                            .googleAds"
                          :key="key"
                        >
                         !!!! {{ i[0][key] == undefined ? i[0].spend : i[0][key] }}
                        </td>
                      </template>
                    </tr>
                  </template>
                  <template v-if="item.key === 'meta'">
                    <tr
                      v-for="(i, dataIndex) in item.dateWiseData"
                      :key="dataIndex"
                    >
                    <template v-if="businessTypeProject == 'leadgen' && salesforceDataFound">
                      <td
                        v-for="(value, key) in projectsMap.projects['leadgen']
                          .withLeadSalesforce"
                        :key="key"
                      >
                      <span v-if="key === 'sales' || key === 'qualityLeads' || key === 'costPerQualityLeads' || key === 'siteVisits'">
                        {{ 
                          i && 
                          (i[0] && i[0].salesforce && i[0].salesforce.hasOwnProperty(key) 
                            ? removeINR(i[0].salesforce[key]) 
                            : (i.salesforce && i.salesforce.hasOwnProperty(key) 
                                ? removeINR(i.salesforce[key]) 
                                : "0")
                          )
                        }}
                      </span>
                      <span v-else>
                        {{ 
                          i && 
                          (i[0] && i[0].hasOwnProperty(key) 
                            ? removeINR(i[0][key]) 
                            : (i.hasOwnProperty(key) 
                                ? removeINR(i[key]) 
                                : "0")
                          )
                        }}
                      </span>
                      </td>
                    </template>
                      <template v-else-if="businessTypeProject == 'leadgen'">
                        <td
                          v-for="(value, key) in projectsMap.projects['leadgen']
                            .googleAds"
                          :key="key"
                        >
                          {{ i[0][key] == undefined ? i[0].spend : i[0][key] }}
                        </td>
                      </template>
                      <template v-else-if="businessTypeProject == 'leadform' && salesforceDataFound">
                        <td
                          v-for="(value, key) in projectsMap.projects['leadform']
                            .withLeadSalesforce"
                          :key="key"
                        >
                        <span v-if="key === 'sales' || key === 'qualityLeads' || key === 'costPerQualityLeads' || key === 'siteVisits'">
                          {{ 
                            i && 
                            (i[0] && i[0].salesforce && i[0].salesforce.hasOwnProperty(key) 
                              ? removeINR(i[0].salesforce[key]) 
                              : (i.salesforce && i.salesforce.hasOwnProperty(key) 
                                  ? removeINR(i.salesforce[key]) 
                                  : "0")
                            )
                          }}
                        </span>
                        <span v-else>
                          {{ 
                            i && 
                            (i[0] && i[0].hasOwnProperty(key) 
                              ? removeINR(i[0][key]) 
                              : (i.hasOwnProperty(key) 
                                  ? removeINR(i[key]) 
                                  : "0")
                            )
                          }}
                        </span>
                        </td>
                      </template>
                        <template v-else-if="businessTypeProject == 'leadform'">
                          <td
                            v-for="(value, key) in projectsMap.projects['leadform']
                              .googleAds"
                            :key="key"
                          >
                            {{ i[0][key] == undefined ? i[0].spend : i[0][key] }}
                          </td>
                        </template>
                      <template v-else>
                        <td
                          v-for="(value, key) in projectsMap.projects['default']
                            .googleAds"
                          :key="key"
                        >
                          {{ i[0][key] == undefined ? i[0].spend : i[0][key] }}
                        </td>
                      </template>
                    </tr>
                  </template>
                  <template v-if="item.key === 'leadSquared'">
                    <tr
                      v-for="(i, dataIndex) in item.dateWiseData"
                      :key="dataIndex"
                    >
                      <template v-if="businessTypeProject == 'leadgen'">
                        <td
                          v-for="(value, key) in projectsMap.projects['leadgen']
                            .leadSquared"
                          :key="key"
                        >
                          {{ i[0][key] == undefined ? i[0].spend : i[0][key] }}
                        </td>
                      </template>
                      <template v-else-if="businessTypeProject == 'leadform'">
                        <td
                          v-for="(value, key) in projectsMap.projects['leadform']
                            .leadSquared"
                          :key="key"
                        >
                          {{ i[0][key] == undefined ? i[0].spend : i[0][key] }}
                        </td>
                      </template>
                      <template v-else>
                        <td
                          v-for="(value, key) in projectsMap.projects['default']
                            .leadSquared"
                          :key="key"
                        >
                          {{ i[0][key] == undefined ? i[0].spend : i[0][key] }}
                        </td>
                      </template>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </b-card> -->
  </div>
</template>

<script>
import projectsMap from "@/@core/utils/projectDetailMap";
import { BCard, BCardTitle, BFormSelect } from "bootstrap-vue";
export default {
  data() {
    return {
      projectsMap: projectsMap,
      items: [],
      selectedAdsOption: "overall",
      lightColorHashCodes: [
        "#AFEEEE", // Light Turquoise
        "#E6E6FA", // Light Lavender
        "#F08080", // Light Coral
        "#778899", // Light Slate Gray
        "#9370DB", // Light Indigo
        "#B03060", // Light Maroon
        "#008B8B", // Light Teal
        "#808000", // Light Olive
        "#FFA07A", // Light Salmon
        "#D8BFD8", // Light Orchid
      ],
    };
  },
  watch: {
    filterOption() {
      this.selectedAdsOption = "overall";
    },
  },
  methods: {
    getProjectsBackgroundColor(index) {
      return this.lightColorHashCodes[index % 10] + "!important";
    },
    removeINR(text) {
      text = String(text);
      var pattern = /INR\s*(\d{1,3}(?:,\d{3})*)(?:\.\d+)?/g;
      var result = text.replace(pattern, "$1");
      return result;
    },
    removeEmptyArrays(data) {
      return data.filter(
        (array) =>
          !(
            Array.isArray(array) &&
            array.length === 1 &&
            Object.keys(array[0]).length === 0
          )
      );
    },
    exportData(data, name, dateOverALL) {
    let displayArr = {};

    // Ensure names are mapped properly
    const mapProjectNames = (dataSource) => {
        dataSource.forEach((item, index) => {
            const projectName = this.commonProjectNames[index] || `Project_${index + 1}`; // Fallback if name is missing

            if (Array.isArray(item)) {
                item.forEach((obj) => {
                    obj.name = projectName;
                });
            } else if (typeof item === "object" && item !== null) {
                item.name = projectName;
            }
        });
    };

    // Assign names to the correct dataset
    if (name === "overall") {
        mapProjectNames(dateOverALL);
    } else {
        mapProjectNames(data);
    }

    if (this.selectedAdsOption === "leadSquared") {
        displayArr = {
            ProjectName: "name",
            GrandTotalCRMLeads: "GrandTotalCRMLeads",
            CRMqualified: "CRMqualified",
            CRMqualifiedInvalid: "CRMqualifiedInvalid",
            TotalQualifiedLeads: "TotalQualifiedLeads",
            PercentageQuality: "PercentageQuality",
            CRMadmission: "CRMadmission",
            CRMOrganicAdmission: "CRMOrganicAdmission",
            TotalAdmissions: "TotalAdmissions",
            CRMadmissionInvalid: "CRMadmissionInvalid",
            CRMtotal: "CRMtotal",
            CRMtotalInvalid: "CRMtotalInvalid"
        };
    } else if (this.selectedAdsOption === "overall" && this.leadSquaredIntegrations) {
        displayArr = {
            ProjectName: "name",
            Spends: "costMicros",
            Impressions: "impressions",
            CPM: "cpm",
            Clicks: "clicks",
            CPC: "cpc",
            CTR: "ctr",
            Conversions: "conversions",
            CPL: "costPerConversion",
            GrandTotalCRMLeads: "GrandTotalCRMLeads",
            CRMqualified: "CRMqualified",
            CRMqualifiedInvalid: "CRMqualifiedInvalid",
            TotalQualifiedLeads: "TotalQualifiedLeads",
            PercentageQuality: "PercentageQuality",
            CRMadmission: "CRMadmission",
            CRMOrganicAdmission: "CRMOrganicAdmission",
            TotalAdmissions: "TotalAdmissions",
            CRMadmissionInvalid: "CRMadmissionInvalid",
            CRMtotal: "CRMtotal",
            CRMtotalInvalid: "CRMtotalInvalid"
        };
    } else {
        displayArr = {
            ProjectName: "name",
            Spends: "costMicros",
            Impressions: "impressions",
            CPM: "cpm",
            Clicks: "clicks",
            CPC: "cpc",
            CTR: "ctr",
            Conversions: "conversions",
            CPL: "costPerConversion"
        };
    }

    let newData = name === "overall" ? dateOverALL.flat() : data.flat();

    if (!Array.isArray(newData) || newData.length === 0) {
        console.error("Data is empty or not an array");
        return;
    }

    console.log("Final Data Before Export:", newData); // Debugging to confirm names exist

    // Function to escape CSV values
    const escapeCSV = (str) => {
        if (str === null || str === undefined) {
            return '""';
        }
        return `"${String(str).replace(/"/g, '""')}"`;
    };

    // Function to get nested values from objects using a string path
    const getNestedValue = (obj, path) => {
        return path.split(".").reduce((acc, part) => acc && acc[part], obj);
    };

    // Create CSV header
    const header = Object.keys(displayArr)
        .map((displayName) => escapeCSV(displayName.charAt(0).toUpperCase() + displayName.slice(1)))
        .join(",");

    // Create CSV rows
    const rows = [header].concat(
        newData.map((item) => {
            return Object.keys(displayArr)
                .map((key) => {
                    let value = getNestedValue(item, displayArr[key]);
                    if (key === "ProjectName") value = item.name; // Ensure proper name mapping
                    return escapeCSV(value);
                })
                .join(",");
        })
    );

    const csv = rows.join("\n");
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `${name}.csv`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}



  },
  computed: {
    filteredKeys() {
      let res = this.allKeys.filter((key) => key.key == this.selectedAdsOption);
      return res;
    },
    allOptions() {
      const AllTypeKeys = Object.keys(this.tableData || {});
      // AllTypeKeys.reverse(); // If you need to reverse the order, uncomment this line.
      const keysWithData = AllTypeKeys.map((key) => {
        return {
          key: key,
        };
      });
      let storeNames = keysWithData.map((item) => item.key);
      if (this.type === "leadSquareAds") {
        this.selectedAdsOption = storeNames[0];
        storeNames = storeNames.slice(0, 1);
      }
      const overallIndex = storeNames.indexOf("overall");
      if (overallIndex !== -1) {
        storeNames.splice(overallIndex, 1);
        storeNames.unshift("overall");
      }
      if (storeNames.length === 1) {
        this.selectedAdsOption = storeNames[0];
      }
      return storeNames;
    },
    allKeys() {
      const AllTypeKeys = Object.keys(this.tableData || {});
      const keysWithData = AllTypeKeys.map((key) => {
        return {
          key: key,
          dateWiseData: this.tableData[key].map((name) => {
            return name.dateWiseData?.map((i) => {
              return i.insights;
            });
          }),
          overAllInsights: this.tableData[key].map((i) => {
            return i.insights;
          }),
        };
      });
      return keysWithData;
    },
    commonProjectNames() {
      const cleanOverALLProjectNames = Object.keys(
        this.tableData.overall || {}
      ).map((key) => this.tableData.overall[key].projectName);
      const cleanMetaProjectNames = Object.keys(this.tableData.meta || {}).map(
        (key) => this.tableData.meta[key].projectName
      );
      const cleanLeadSquareProjectNames = Object.keys(
        this.tableData.leadSquared || {}
      ).map((key) => this.tableData.leadSquared[key].projectName);
      const googleAdsProjectNames = Object.keys(
        this.tableData.googleAds || {}
      ).map((key) => this.tableData.googleAds[key].projectName);
      if (this.selectedAdsOption == "overall") {
        return cleanOverALLProjectNames;
      }
      if (this.selectedAdsOption == "googleAds") {
        return googleAdsProjectNames;
      }
      if (this.selectedAdsOption == "meta") {
        return cleanMetaProjectNames;
      }
      if (this.selectedAdsOption == "leadSquared") {
        return cleanLeadSquareProjectNames;
      }
    },
  },
  components: {
    BCard,
    BCardTitle,
    BFormSelect,
  },
  props: {
    projectName: {
      type: String,
    },
    tableData: {
      type: Object,
    },
    type: {
      type: String,
    },
    businessTypeProject: {
      type: String,
    },
    leadSquaredIntegrations: {
      type: Boolean,
    },
    isEmptyFlag: {
      type: Boolean,
    },
    salesforceDataFound:{
      type:Boolean
    },
    roleType:{
      type: String,
    }
  },
};
</script>

<style lang="scss">
.table-multiheader-container {
  display: flex;
  width: 100%;

  overflow-x: auto;

  .table thead th {
    // border-bottom: 1px solid #ebe9f1;
    font-size: 11px !important;
    white-space: nowrap;
    width: 400px !important;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    border: 0.5px solid #ddd;
    text-align: center;
    font-size: 11px;
    color: black;
    height: 50px;
  }

  .dateTd {
    color: black;
    font-size: 11px !important;
  }

  th {
    color: black;
    background-color: #eff5f5 !important;
  }

  .padding0 {
    padding: 0px;
  }
}

.date-column {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white;
  color: black;

  .padding0 {
    padding: 0px;
  }

  .paddingMeta0 {
    padding: 0 2px !important;
  }

  td {
    color: black;
    background-color: #ffffff;
  }
}

.dateProjectTd {
  font-size: 11px !important;
  color: black;
}

.dateFacebookProjectTd {
  color: black;
  font-size: 11px !important;
}

.spinnerCentered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
